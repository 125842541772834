.wrapper {
  height: fit-content;
  width: 100%;
  justify-self: center;
  align-self: center;
}

.cardContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 32px;
}
