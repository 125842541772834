.topBanner {
  background-image: url('../../../components/assets/background.svg'),
    linear-gradient(266deg, #28aae2 0.82%, #006095 98.04%);
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 24px;
}
.contentContainer {
  max-width: 1200px;
  width: 100%;
}
.languageButton {
  position: absolute !important;
  top: 40px;
  right: 40px;
}
.content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.logo {
  height: 104px;
  width: 300px;
}
.title {
  font-size: 24px;
  font-weight: 800;
  line-height: normal;
  color: var(--gray-1);
}
.description {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--gray-1);
}
@media (min-width: 800px) and (max-width: 1024px) {
  .topBanner {
    padding: 40px 40px;
  }
  .content {
    max-width: 720px;
  }
}
@media (max-width: 800px) {
  .topBanner {
    padding: 40px 40px;
  }
  .content {
    max-width: 520px;
  }
}
