.container {
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  width: 100%;
}

.logo {
  height: 60px;
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .container {
    padding: 40px 24px;
    gap: 40px;
  }
}
