:root{
	--body-1: #383f72;
	--body-2: #6e7190;
	--body-3: #8a8da8;

	--gray-1: #ffffff;
	--gray-2: #F8F9FC;
	--gray-3: #F0F3F8;
	--gray-4: #D8E1EB;
	--gray-5: #B5C6D8;
	--gray-6: #90A9C1;
	--gray-7: #486F90;
	--gray-8: #18486B;
	--gray-9: #003457;
	--gray-10: #00253D;

	--blue-1: #F2FBFE;
	--blue-2: #C2ECFB;
	--blue-3: #91DCF8;
	--blue-4: #61CDF5;
	--blue-5: #31BDF2;
	--blue-6: #00AEEF;
	--blue-7: #008FC7;
	--blue-8: #0075A2;
	--blue-9: #005673;
	--blue-10: #004357;

	--red-1: #FCF6F5;
	--red-2: #F1D4CF;
	--red-3: #E6B2A9;
	--red-4: #DB8F84;
	--red-5: #D06D5E;
	--red-6: #C54B38;
	--red-7: #A73928;
	--red-8: #86291B;
	--red-9: #631B10;
	--red-10: #3D0F08;

	--volcano-1: #FFF2E8;
	--volcano-2: #ffd8bf;
	--volcano-3: #ffbb96;
	--volcano-4: #ff9c6e;
	--volcano-5: #ff7a45;
	--volcano-6: #fa541c;
	--volcano-7: #d4380d;
	--volcano-8: #ad2102;
	--volcano-9: #871400;
	--volcano-10: #610b00;

	--orange-1: #fff7e6;
	--orange-2: #ffe7ba;
	--orange-3: #ffd591;
	--orange-4: #ffc069;
	--orange-5: #fa8c16;
	--orange-6: #fa8c16;
	--orange-7: #d46b08;
	--orange-8: #ad4e00;
	--orange-9: #873800;
	--orange-10: #612500;

	--gold-1: #FFFBF2;
	--gold-2: #FFEEC2;
	--gold-3: #FFE091;
	--gold-4: #FFD261;
	--gold-5: #FFC431;
	--gold-6: #FFB600;
	--gold-7: #D19900;
	--gold-8: #A27B00;
	--gold-9: #735A00;
	--gold-10: #453700;

	--yellow-1: #feffe6;
	--yellow-2: #ffffb8;
	--yellow-3: #fffb8f;
	--yellow-4: #fff566;
	--yellow-5: #ffec3d;
	--yellow-6: #fadb14;
	--yellow-7: #d4b106;
	--yellow-8: #ad8b00;
	--yellow-9: #876800;
	--yellow-10: #614700;

	--lime-1: #FCFFE6;
	--lime-2: #F4FFB8;
	--lime-3: #EAFF8F;
	--lime-4: #D3F261;
	--lime-5: #BAE637;
	--lime-6: #A0D911;
	--lime-7: #7CB305;
	--lime-8: #5B8C00;
	--lime-9: #3F6600;
	--lime-10: #254000;

	--green-1: #f5ffeb;
	--green-2: #d9f7bf;
	--green-3: #b7eb8e;
	--green-4: #94de63;
	--green-5: #73d13d;
	--green-6: #53c71a;
	--green-7: #39a00d;
	--green-8: #227704;
	--green-9: #135200;
	--green-10: #092900;

	--cyan-1: #e6fffb;
	--cyan-2: #b5f5ec;
	--cyan-3: #87e8de;
	--cyan-4: #5cdbd3;
	--cyan-5: #36cfc9;
	--cyan-6: #13c2c2;
	--cyan-7: #08979c;
	--cyan-8: #006d75;
	--cyan-9: #00474f;
	--cyan-10: #002329;

	--geekblue-1: #f0f5ff;
	--geekblue-2: #d6e4ff;
	--geekblue-3: #adc6ff;
	--geekblue-4: #85a5ff;
	--geekblue-5: #597ef7;
	--geekblue-6: #2f54eb;
	--geekblue-7: #1d39c4;
	--geekblue-8: #10239e;
	--geekblue-9: #061178;
	--geekblue-10: #030852;

	--purple-1: #f9f0ff;
	--purple-2: #efdbff;
	--purple-3: #d3adf7;
	--purple-4: #b37feb;
	--purple-5: #9254de;
	--purple-6: #722ed1;
	--purple-7: #531dab;
	--purple-8: #391085;
	--purple-9: #22075e;
	--purple-10: #120338;

	--magenta-1: #fff0f6;
	--magenta-2: #ffd6e7;
	--magenta-3: #ffadd2;
	--magenta-4: #ff85c0;
	--magenta-5: #f759ab;
	--magenta-6: #eb2f96;
	--magenta-7: #c41d7f;
	--magenta-8: #9e1068;
	--magenta-9: #780650;
	--magenta-10: #520339;

	/* Colors: */
	--preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime,
  green, blue, geekblue, purple;

}