body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#root {
  height: 100%;
}

.appContainer {
  height: 100vh;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}
